import type { APIDefinitionUploadType } from '@readme/api/src/mappings/apis/types';

import kebabCase from 'lodash/kebabCase';

/**
 * The `/apis` endpoint expects a multipart form data payload when creating or updating an API
 * definition. This function converts form payload into multipart form data to send as our request body.
 */
export function normalizeApiDefinitionPayload(payload: APIDefinitionUploadType) {
  return Object.entries(payload).reduce((data, [key, value]) => {
    if (key === 'schema' && !(payload.schema instanceof File)) {
      // The API expects the `schema` property to be a File so we need to convert the schema JSON value into a
      // Blob and attach it to the form data.
      data.set(
        key,
        new Blob([JSON.stringify(value)], { type: 'application/json' }),
        `${kebabCase(value.info.title)}.json`,
      );
    } else {
      data.set(key, value);
    }

    return data;
  }, new FormData());
}
