import type { SuperHubDocumentData } from '.';
import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { superHubStore } from '..';

/**
 * Empty guide page to be used when creating a new page.
 */
export function createDefaultGuidePage(): Partial<ReadGuideType['data']> {
  return {
    allow_crawlers: 'enabled',
    content: {
      body: null,
      excerpt: null,
      link: {
        new_tab: null,
        url: null,
      },
      next: {
        description: null,
        pages: [],
      },
    },
    metadata: {
      description: null,
      image: {
        uri: null,
        url: null,
      },
      keywords: null,
      title: null,
    },
    privacy: { view: 'public' },
    renderable: {
      status: true,
    },
    slug: '',
    state: 'current',
    title: '',
    type: 'basic',
  };
}

/**
 * Empty reference page to be used when creating a new page.
 */
export function createDefaultReferencePage(): Partial<ReadReferenceType['data']> {
  return {
    ...createDefaultGuidePage(),
    api: {
      method: 'get',
      path: '',
      schema: null,
      stats: {
        additional_properties: false,
        callbacks: false,
        circular_references: false,
        discriminators: false,
        links: false,
        polymorphism: false,
        references: false,
        server_variables: false,
        style: false,
        webhooks: false,
        xml: false,
      },
      source: null,
      uri: null,
    },
  };
}

/**
 * Empty custom page to be used when creating a new page.
 */
export function createDefaultCustomPage(): Partial<CustomPageReadType['data']> {
  return {
    appearance: {
      fullscreen: false,
    },
    content: {
      body: null,
      type: 'markdown',
    },
    metadata: {
      description: null,
      image: {
        uri: null,
        url: null,
      },
      keywords: null,
      title: null,
    },
    privacy: { view: 'public' },
    slug: '',
    title: '',
  };
}

/**
 * Empty changelog to be used when creating a new post.
 */
export function createDefaultChangelog(): Partial<ReadChangelogType['data']> {
  return {
    author: {
      id: superHubStore.getState().user.id,
      name: superHubStore.getState().user.name,
    },
    created_at: new Date().toISOString(),
    content: {
      body: null,
    },
    metadata: {
      description: null,
      image: {
        uri: null,
        url: null,
      },
      keywords: null,
      title: null,
    },
    privacy: { view: 'public' },
    slug: '',
    title: '',
    type: 'none',
  };
}

/**
 * Returns the empty default document based on the provided route section that
 * represents the initial state when creating new documents.
 */
export function getDefaultDocument(routeSection: SuperHubRouteParams['section'] | null) {
  if (!routeSection) return null;

  /**
   * Hash of default documents by route section to use as the form's empty state
   * when creating new pages.
   */
  const defaultDocumentMap = {
    changelog: createDefaultChangelog(),
    discuss: null,
    docs: createDefaultGuidePage(),
    home: null,
    page: createDefaultCustomPage(),
    recipes: null,
    reference: createDefaultReferencePage(),
  } satisfies Record<NonNullable<SuperHubRouteParams['section']>, Partial<SuperHubDocumentData> | null>;

  return defaultDocumentMap[routeSection] as SuperHubDocumentData;
}
