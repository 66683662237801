export enum HTTP_METHOD {
  BASIC = 'basic',
  CNNCT = 'cnnct',
  CONNECT = 'connect',
  DELETE = 'delete',
  DEPRECATED = 'deprecated',
  DESCRIPTION = 'description',
  ERROR = 'error',
  FN = 'fn',
  GET = 'get',
  HEAD = 'head',
  LINK = 'link',
  OPTIONS = 'options',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
  REQUESTBODY = 'requestbody',
  RESPONSES = 'responses',
  TEST = 'test',
  TRACE = 'trace',
  WEBHOOK = 'webhook',
}

type StandardHTTPMethod =
  | HTTP_METHOD.DELETE
  | HTTP_METHOD.GET
  | HTTP_METHOD.HEAD
  | HTTP_METHOD.OPTIONS
  | HTTP_METHOD.PATCH
  | HTTP_METHOD.POST
  | HTTP_METHOD.PUT
  | HTTP_METHOD.TRACE;

// Non-standard methods are from legacy manual APIs
type NonStandardHTTPMethod =
  | HTTP_METHOD.BASIC
  | HTTP_METHOD.CNNCT
  | HTTP_METHOD.CONNECT
  | HTTP_METHOD.DEPRECATED
  | HTTP_METHOD.DESCRIPTION
  | HTTP_METHOD.ERROR
  | HTTP_METHOD.FN
  | HTTP_METHOD.LINK
  | HTTP_METHOD.REQUESTBODY
  | HTTP_METHOD.RESPONSES
  | HTTP_METHOD.TEST
  | HTTP_METHOD.WEBHOOK;

type BaseHTTPMethod = NonStandardHTTPMethod | StandardHTTPMethod;

/** HTTP method valid in either upper or lower case */
export type HTTPMethod = BaseHTTPMethod | Uppercase<BaseHTTPMethod>;
