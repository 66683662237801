import React, { useContext, useEffect } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useMetricsAPI from '@core/hooks/useMetricsAPI';

import type { CustomerUsageData } from '@routes/Dash/Project/Metrics/types/props';

import { useMetricsStore } from '.';

export interface InitializeMetricsStore {
  children: React.ReactNode;
}

/**
 * Middleware component connects to our Project contexts, fetches customer usage data from Metrics API,
 * handles route change side-effects, and initializes our Metrics store.
 */
export function InitializeMetricsStore({ children }: InitializeMetricsStore) {
  const [initialize, isReady] = useMetricsStore(s => [s.initialize, s.isReady]);

  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const { data } = useMetricsAPI<CustomerUsageData>('requests/usage');

  // Initialize the store when we have customer usage data
  // and the store is not already initialized
  useEffect(() => {
    if (!isReady && !!data) {
      initialize({
        usage: data,
        project: {
          metrics: project.metrics,
          onboardingCompleted: project.onboardingCompleted,
        },
      });
    }
  }, [isReady, data, initialize, project]);

  return <>{children}</>;
}
