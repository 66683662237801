import type { ReadAPIDefinitionType } from '@readme/api/src/mappings/apis/types';
import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

import { produce } from 'immer';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useReadmeApi, { useReadmeApiNext } from '@core/hooks/useReadmeApi';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { useSuperHubStore } from '..';

import { getDefaultDocument } from './defaults';
import { isReferencePage } from './util';

interface ConnectSuperHubDocumentToApiProps {
  children: React.ReactNode;
  /**
   * Whether SWR should revalidate page data on focus. Default is `false`.
   */
  revalidateOnFocus?: boolean;
}

/**
 * Connects our SuperHub store's document slice to the page API endpoint via SWR
 * based on the current route section and slug. Fetches new data whenever
 * rendered and updates the store.
 */
export function ConnectSuperHubDocumentToApi({
  children,
  revalidateOnFocus = false,
}: ConnectSuperHubDocumentToApiProps) {
  const { section, slug } = useParams<SuperHubRouteParams>();
  const [
    getApiEndpoint,
    initialize,
    getReferencePageData,
    isEditing,
    isCreateNewPage,
    firstApiDefinition,
    isApiListLoading,
    defaultApiOptions,
  ] = useSuperHubStore(s => [
    s.getApiEndpoint,
    s.document.initialize,
    s.document.getReferencePageData,
    s.isEditing,
    s.editor.isCreateNewPage,
    s.apiDefinitions.data?.[0],
    s.apiDefinitions.isLoading,
    s.defaultApiOptions,
  ]);

  const apiUrl = slug ? getApiEndpoint(slug) : null;
  const {
    data: documentData = isCreateNewPage ? { data: getDefaultDocument(section) } : undefined,
    isLoading: isDocumentLoading,
    swrKey: swrKeyDocument,
  } = useReadmeApi<ReadGuideType>(apiUrl, {
    ...defaultApiOptions,
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  // Temporarily limit custom block data fetching to only the docs and reference sections.
  // We'll want to load custom blocks for custom pages and changelogs once there's API support.
  const isCustomBlockRoute = ['docs', 'reference'].includes(section || '');

  const {
    data: customBlocksData,
    isLoading: isCustomBlocksLoading,
    swrKey: swrKeyCustomBlocks,
  } = useReadmeApi<ReadCustomBlockGitCollectionType>(apiUrl && isCustomBlockRoute ? `${apiUrl}/custom_blocks` : null, {
    swr: {
      ...defaultApiOptions,
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  // If we are editing a reference page, get the URL to fetch the full API definition.
  const apiDefinitionUri = useMemo(() => {
    if (section !== 'reference' || !isEditing) return null;

    if (getReferencePageData()?.api.uri) return getReferencePageData()?.api.uri || null;

    return firstApiDefinition?.uri || null;
  }, [firstApiDefinition, getReferencePageData, isEditing, section]);

  const { data: apiDefinitionData, isLoading: isApiLoading } = useReadmeApiNext<ReadAPIDefinitionType>(
    apiDefinitionUri,
    {
      swr: {
        revalidateOnFocus,
        shouldRetryOnError: true,
      },
    },
  );

  /**
   * Indicates whether data is loading or not. Because we require multiple API
   * fetches to hydrate document data, we must evalute multiple loading states.
   */
  const isLoading = isDocumentLoading || isCustomBlocksLoading || isApiListLoading || isApiLoading;

  /**
   * Indicates whether both document and custom blocks hydration is synced up.
   * This is important b/c we only want to update our store after both request
   * states are either pending or copmleted. This is equivalent to a
   * `Promise.all(documentFetch, customBlocksFetch).`
   */
  const isLoadingStateStable = isDocumentLoading === isCustomBlocksLoading;

  const nextDocumentData = produce(documentData, draft => {
    if (apiDefinitionData?.data && isReferencePage(draft?.data)) {
      draft.data.api.schema = apiDefinitionData.data.schema;
      draft.data.api.uri = apiDefinitionData.data.uri;
    }
  });

  useEffect(() => {
    if (isLoadingStateStable) {
      initialize({
        customBlocks: customBlocksData?.data || [],
        apiDefinition: apiDefinitionData?.data || null,
        data: nextDocumentData?.data || null,
        isLoading,
        swrKeyCustomBlocks,
        swrKeyDocument,
      });
    }
  }, [
    customBlocksData?.data,
    nextDocumentData?.data,
    initialize,
    isLoading,
    isLoadingStateStable,
    swrKeyCustomBlocks,
    swrKeyDocument,
    apiDefinitionData?.data,
  ]);

  return <>{children}</>;
}
