import type { HTTPMethod } from '../types';

export interface SuggestedEndpoint {
  api: {
    file: string;
    method: HTTPMethod;
    operationId: string;
    url: string;
  };
  category: {
    title: string;
  };
  page?: {
    _id: string;
  };
  title: string;
}

/** Response for /realtime/get-suggested-endpoints */
export interface SuggestedEndpointsResponse {
  all: SuggestedEndpoint[];
  recommended: SuggestedEndpoint[];
}

// readme-specific name for categorization our api config files
export const README_CONFIG = 'ReadMeConfig';
