import type { APIDesignerStore } from '..';
import type { HttpMethods } from 'oas/types';
import type { OpenAPIV3 } from 'openapi-types';
import type { StateCreator } from 'zustand';

import { codes } from '@readme/http-status-codes';
import Oas from 'oas';

import { actionLog } from '@core/store/util';

interface AugmentedResponseExample {
  /**
   * Should be a concatenation of the status code and the summary. If the summary
   * doesn't exist, it will just be the status code
   * ie. 200_user or 200
   */
  id: string;
  mediaType: string;
  namedId: string;
  status: string;
}

export interface ResponseExampleEditorSliceState {
  /**
   * Determines if the editor is in the initial state with no examples
   * or a custom state where the user is writing custom examples.
   */
  editorState: 'custom' | 'initial';

  /**
   * Indicates whether the given operation has resposne examples or not.
   */
  hasExamples: boolean;

  /**
   * Keeps track of the number of newly created examples so we can append
   * a unique identifier to the example name and let the user add as many
   * exapmles as they want.
   */
  newExampleCount: number;

  /**
   * The category of the currently selected status code.
   * For example, if the 200 status code is selected, this will be "Success"
   */
  selectedCodeCategory: 'error' | 'success' | 'warning';

  /**
   * The currently selected response example.
   */
  selectedExample: AugmentedResponseExample | null;
}

export interface MediaTypeExample {
  description?: string;
  summary?: string;
  title?: string;
  value: unknown;
}

export interface ResponseExampleEditorSliceActions {
  /**
   * Adds a custom response example to the current operation.
   */
  addResponseExample: () => void;

  /**
   * Deletes the currently selected example.
   */
  deleteResponseExample: () => void;

  /**
   * Returns the response example properties on the operation object.
   */
  getOperationResponseProperties: (
    contentType: string,
    statusCode: string,
  ) => {
    hasContentType: boolean;
    hasExamplesObject: boolean;
    hasMultipleContentTypes: boolean;
    hasMultipleExamples: boolean;
    hasResponseObject: boolean;
    hasStatusCode: boolean;
  };

  /**
   * Returns the properties of the currently selected example.
   */
  getSelectedExampleProperties: () => {
    currentContentType: AugmentedResponseExample['mediaType'];
    currentStatusCode: AugmentedResponseExample['status'];
    namedId: AugmentedResponseExample['namedId'];
  };

  initialize: () => void;

  /**
   * Sets the editor state to the provided value.
   */
  setEditorState: (editorState: ResponseExampleEditorSliceState['editorState']) => void;

  /**
   * Updates the currently selected code category
   */
  setSelectedCodeCategory: (category: ResponseExampleEditorSliceState['selectedCodeCategory']) => void;

  /**
   * Updates the currently selected example
   */
  setSelectedExample: ({ mediaType, status, id, namedId }) => void;

  /**
   * Updates the content-type of the currently selected example.
   */
  updateContentType: (contentType: Record<string, string>) => void;

  /**
   * Updates the code of the currently selected example.
   */
  updateSnippet: (editor, data, value: string) => void;

  /**
   * Updates the status code of the currently selected example.
   */
  updateStatusCode: (statusCode: string) => void;
}

export interface ResponseExampleEditorSlice {
  /**
   * State slice containing fields and actions that pertain to the Response Example Editor
   */
  responseExampleEditor: ResponseExampleEditorSliceActions & ResponseExampleEditorSliceState;
}

export const initialState: ResponseExampleEditorSliceState = {
  editorState: 'initial',
  hasExamples: false,
  newExampleCount: 0,
  selectedCodeCategory: 'success',
  selectedExample: null,
};

/**
 * Response Example Editor state slice containing fields related to the Manual Editor — Response Example Editor.
 */
export const createResponseExampleEditorSlice: StateCreator<
  APIDesignerStore & ResponseExampleEditorSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ResponseExampleEditorSlice
> = (set, get) => ({
  responseExampleEditor: {
    ...initialState,

    /**
     * Cases for adding a new response example:
     * 1. If the operation doesn't have any responses object, we want to create one
     * 2. If the status code doesn't exist, we want to create the status code
     * 3. If the status code exists, we want to check if the content type exists.
     * 4. If the content type doesn't exist, we'll create it
     * 5. If the content type exists, we want to check if the example exists
     * 6. If the examples object doesn't exist, we'll create it
     * 7. If the examples object exists, we'll append the example
     * 8. If the operation uses an `example` object, we need to convert it to `examples` and add the new example
     */
    addResponseExample: () => {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Constructing the scaffolding for a new example
      const newStatus = '200';
      const newContentType = 'application/json';
      const newExampleLabel = `newExample${get().responseExampleEditor.newExampleCount === 0 ? '' : get().responseExampleEditor.newExampleCount}`;

      const newExampleValue = {
        summary: newExampleLabel,
        value: ' ',
      };

      const newFullExample = {
        description: 'OK',
        content: {
          'application/json': {
            examples: {
              [newExampleLabel]: newExampleValue,
            },
          },
        },
      };

      // Determining which properties exist on the operation object
      const { hasResponseObject, hasStatusCode, hasContentType, hasExamplesObject } =
        get().responseExampleEditor.getOperationResponseProperties(newContentType, newStatus);

      const hasExampleObject = !!(operation!.responses![newStatus] as OpenAPIV3.ResponseObject)?.content?.[
        newContentType
      ]?.example;

      // Logic that covers all the cases for adding a new example
      if (!hasResponseObject) {
        // Adding Case #1
        newOperation!.responses = {
          [newStatus]: newFullExample,
        };
      } else if (hasResponseObject && !hasStatusCode) {
        // Adding Case #2
        newOperation!.responses![newStatus] = newFullExample;
      } else if (hasResponseObject && hasStatusCode && !hasContentType) {
        // Adding Case #4
        (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content = {
          [newContentType]: {
            examples: {
              [newExampleLabel]: newExampleValue,
            },
          },
        };
      } else if (hasResponseObject && hasStatusCode && hasContentType && !hasExampleObject && !hasExamplesObject) {
        // Adding Case #6
        (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content![newContentType]!.examples = {
          [newExampleLabel]: newExampleValue,
        };
      } else if (hasResponseObject && hasStatusCode && hasContentType && hasExampleObject && !hasExamplesObject) {
        // Adding Case #8
        const oldExample = (operation!.responses![newStatus] as OpenAPIV3.ResponseObject)?.content?.[newContentType]
          ?.example;

        newOperation!.responses![newStatus] = {
          content: {
            [newContentType]: {
              examples: {
                [`exampleFromExisting${newStatus}`]: {
                  value: oldExample,
                },
                [newExampleLabel]: newExampleValue,
              },
            },
          },
          description: 'OK',
        };

        delete (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject)?.content?.[newContentType]?.example;
      } else if (hasResponseObject && hasStatusCode && hasContentType && hasExamplesObject) {
        // Adding Case #7
        (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content![newContentType]!.examples![
          newExampleLabel
        ] = newExampleValue;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.newExampleCount += 1;
          state.responseExampleEditor.selectedExample = {
            mediaType: newContentType,
            namedId: newExampleLabel,
            status: newStatus.toString(),
            id: newStatus.toString().concat('_', newExampleLabel),
          };
          state.responseExampleEditor.editorState = 'custom';
          state.responseExampleEditor.hasExamples = true;
        },
        false,
        actionLog('adding response example', {}),
      );
    },

    /**
     * Cases for deleting:
     * 1. If the content-type has multiple examples, we only want to delete the current example
     * 2. If there is only one example, we can delete that content-type
     * 3. If there is only one example and one content-type, we can delete the entire status code
     */
    deleteResponseExample: () => {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      // Determining if the operation has multiple content types or multiple examples
      const { hasMultipleContentTypes, hasMultipleExamples } =
        get().responseExampleEditor.getOperationResponseProperties(currentContentType, currentStatusCode);

      if (hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)!.content![currentContentType]!
          .examples![namedId];
      } else if (hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)!.content![currentContentType];
      } else if (!hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #3
        delete newOperation!.responses![currentStatusCode];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('deleting response example', {}),
      );
    },

    getOperationResponseProperties: (contentType: string, statusCode: string) => {
      const { operation } = get().getCurrentOperation();
      const hasResponseObject = !!operation?.responses || false;
      const hasStatusCode = !!(operation?.responses && operation?.responses?.[statusCode]) || false;

      const hasContentType =
        !!(
          operation?.responses &&
          operation?.responses?.[statusCode] &&
          (operation.responses?.[statusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType]
        ) || false;

      const hasMultipleContentTypes =
        Object.keys((operation?.responses?.[statusCode] as OpenAPIV3.ResponseObject)?.content || {}).length > 1;

      const hasExamplesObject =
        !!(
          operation?.responses &&
          operation?.responses?.[statusCode] &&
          (operation.responses?.[statusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType] &&
          (operation.responses?.[statusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType]?.examples
        ) || false;

      const hasMultipleExamples =
        Object.keys(
          (operation?.responses?.[statusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType]?.examples || {},
        ).length > 1;

      return {
        hasResponseObject,
        hasStatusCode,
        hasContentType,
        hasMultipleContentTypes,
        hasExamplesObject,
        hasMultipleExamples,
      };
    },

    getSelectedExampleProperties: () => {
      const namedId = get().responseExampleEditor.selectedExample!.namedId;
      const currentStatusCode = get().responseExampleEditor.selectedExample!.status;
      const currentContentType = get().responseExampleEditor.selectedExample!.mediaType;

      return { namedId, currentStatusCode, currentContentType };
    },

    initialize: () => {
      const { currentPath, currentMethod } = get().getCurrentOperation();

      const oas = new Oas(get().apiObject?.schema || '{}');
      const operation = oas.operation(currentPath, currentMethod as HttpMethods);
      const hasExamples = operation.getResponseExamples().length > 0;

      const firstExample = operation.getResponseExamples()[0];
      const firstStatus = firstExample?.status || '';
      const firstMediaType = firstExample ? Object.keys(firstExample.mediaTypes)[0] : '';
      const firstNamedId = firstMediaType ? firstExample.mediaTypes[firstMediaType][0]?.summary : '';

      // If there's an example, let's automatically select it.
      const selectedExample =
        get().responseExampleEditor.selectedExample === null && !!firstExample && !!firstMediaType && !!firstStatus
          ? {
              mediaType: firstMediaType,
              status: firstStatus,
              id: firstNamedId ? firstStatus.concat('_', firstNamedId) : firstStatus,
              namedId: firstNamedId as string,
            }
          : get().responseExampleEditor.selectedExample;

      set(
        state => {
          state.responseExampleEditor.editorState = hasExamples ? 'custom' : 'initial';
          state.responseExampleEditor.hasExamples = hasExamples;
          state.responseExampleEditor.selectedExample = selectedExample;
        },
        false,
        actionLog('initialize response example editor slice', {}),
      );
    },

    setEditorState(editorState: ResponseExampleEditorSliceState['editorState']) {
      set(
        state => {
          state.responseExampleEditor.editorState = editorState;
        },
        false,
        actionLog('setting response editor state', { editorState }),
      );
    },

    setSelectedCodeCategory(category: ResponseExampleEditorSliceState['selectedCodeCategory']) {
      set(
        state => {
          state.responseExampleEditor.selectedCodeCategory = category;
        },
        false,
        actionLog('setting selected code category', { category }),
      );
    },

    setSelectedExample({ mediaType, status, id, namedId }) {
      set(
        state => {
          state.responseExampleEditor.selectedExample = {
            id,
            mediaType,
            namedId,
            status,
          };
        },
        false,
        actionLog('setting selected response example', { mediaType, status, id, namedId }),
      );
    },

    /**
     * Cases for adding:
     * 1. If content-type doesn't exist, we want to create a new content-type
     * 2. If content-type does exist, we want to just append an example
     *
     * Cases for deleting:
     * 1. If the content-type has multiple examples, we only want to delete the current example
     * 2. If the content-type only has one example,  we can delete the content-type
     *
     * NOTE: Status code should always exist since the user should currently be on an example
     */
    updateContentType(contentType: Record<string, string>) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Covering the adding cases
      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      const hasNewContentType =
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType.contentType] ||
        false;

      let newLabel;
      if (!namedId) {
        newLabel = `exampleFromExisting${currentStatusCode}`;
      }

      const hasExamplesObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.examples;
      const hasExampleObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.example;

      let code;
      if (hasExamplesObject) {
        code = (
          (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
            ?.examples?.[namedId || newLabel] as OpenAPIV3.ExampleObject
        )?.value;
      } else if (hasExampleObject) {
        code = (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      if (!hasNewContentType) {
        // Adding Case #1
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![contentType.contentType] = {
          examples: { [namedId || newLabel]: { value: code } },
        };
      } else if (hasNewContentType) {
        // Adding Case #2
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![
          contentType.contentType
        ].examples![namedId || newLabel] = { value: code };
      }

      // Covering the deleting cases
      const { hasMultipleExamples } = get().responseExampleEditor.getOperationResponseProperties(
        currentContentType,
        currentStatusCode,
      );

      if (hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]!
          .examples![namedId];
      } else if (!hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.selectedExample = {
            mediaType: contentType.contentType,
            namedId,
            status: currentStatusCode,
            id: get().responseExampleEditor.selectedExample!.id,
          };
        },
        false,
        actionLog('updating code snippet', {}),
      );
    },

    /**
     * NOTE:
     * Since we're updating a snippet on an existing example, `selectedExample` will always exist
     */
    updateSnippet(_editor, _data, value) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      const hasExamplesObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.examples;
      const hasExampleObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.example;

      let code;
      if (hasExamplesObject) {
        code = (
          (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
            ?.examples?.[namedId] as OpenAPIV3.ExampleObject
        )?.value;
      } else if (hasExampleObject) {
        code = (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      if (code) {
        if (hasExamplesObject) {
          (
            (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]
              .examples![namedId] as OpenAPIV3.ExampleObject
          ).value = value;
        } else if (hasExampleObject) {
          let newSnippet;
          try {
            newSnippet = JSON.parse(value);
          } catch (e) {
            return;
          }
          (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![
            currentContentType
          ].example = newSnippet;
        }
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updating code snippet', { value }),
      );
    },

    /**
     * Cases for adding:
     * 1. If a status code does not exist, we want to create the new response object.
     * 2. If a status code exists, but the `content` object does not, we want to create a new `content` object.
     * 3. If a status code and `content` object exists, but content-type does not, we want to create a new content-type.
     * 4. If a status code, `content` object, and content-type exists, and it's not using an `example` object, we just want to append the example.
     * 5. If a status code, `content` object, and content-type exists but it's using an `example` object, we want to convert it.
     *
     * Cases for deleting:
     * 1. If the status code has multiple examples, we only want to delete the current example.
     * 2. If the status code has only one example, but multiple content-types, we want to delete that content-type.
     * 3. If the status code has only one example and one content-type, we want to just delete the status code.
     *
     * NOTE:
     * `selectedExample` should always exist since the user won't be able to update the status code without selecting an example
     * `operation.responses` should also exist since you won't be able to update the status code without already having a response object
     */
    updateStatusCode(statusCode: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Covering the adding cases
      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      let newLabel;
      if (!namedId) {
        newLabel = `exampleFromExisting${statusCode}`;
      }

      const hasNewStatusCode = !!newOperation!.responses![statusCode] || false;
      const newStatusUsingExampleObject = !!(newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)
        ?.content![currentContentType].example;
      const hasNewContent = (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content;
      const hasNewContentType = (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ];

      const hasExamplesObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.examples;
      const hasExampleObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.example;

      let code;
      if (hasExamplesObject) {
        code = (
          (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
            ?.examples?.[namedId || newLabel] as OpenAPIV3.ExampleObject
        )?.value;
      } else if (hasExampleObject) {
        code = (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      if (!hasNewStatusCode) {
        // Adding Case #1
        newOperation!.responses![statusCode] = {
          description: codes[statusCode][0],
          content: {
            [currentContentType]: {
              examples: { [namedId || `exampleFromExisting${currentStatusCode}`]: { value: code } },
            },
          },
        };
      } else if (hasNewStatusCode && !hasNewContent) {
        // Adding Case #2
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content = {
          [currentContentType]: {
            examples: { [namedId || newLabel]: { value: code } },
          },
        };
      } else if (hasNewStatusCode && hasNewContent && !hasNewContentType) {
        // Adding Case #3
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType] = {
          examples: { [namedId || newLabel]: { value: code } },
        };
      } else if (hasNewStatusCode && hasNewContent && hasNewContentType && !newStatusUsingExampleObject) {
        // Adding Case #4
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType].examples![
          namedId || newLabel
        ] = {
          value: code,
        };
      } else if (hasNewStatusCode && hasNewContent && hasNewContentType && newStatusUsingExampleObject) {
        const oldExample = (operation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[
          currentContentType
        ]?.example;

        // Converting the original `example` object to an `examples` object
        newOperation!.responses![statusCode] = {
          content: {
            [currentContentType]: {
              examples: {
                [`exampleFromExisting${currentStatusCode}`]: {
                  value: oldExample,
                },
              },
            },
          },
          description: codes[currentStatusCode][0],
        };

        // Appending the new example
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType].examples![
          namedId || newLabel
        ] = {
          value: code,
        };

        // Deleting the old `example` object
        delete (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      // Covering the deleting cases
      const { hasStatusCode, hasMultipleContentTypes, hasMultipleExamples } =
        get().responseExampleEditor.getOperationResponseProperties(currentContentType, currentStatusCode);

      if (hasStatusCode && hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]!
          .examples![namedId];
      } else if (hasStatusCode && hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType];
      } else if (hasStatusCode && !hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #3
        delete newOperation!.responses![currentStatusCode];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updating status code', { statusCode }),
      );
    },
  },
});
