import type { ReadProjectType } from '@readme/api/src/mappings/project/types';

import React, { useEffect } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '../SuperHub';

import { useProjectStore } from '.';

/**
 * Establishes an SWR connection to the v2 API endpoint and continually keeps
 * the project store in sync with the latest data stored in our DB.
 */
export function ConnectProjectStoreToApi({ children }: { children: React.ReactNode }) {
  const [subdomain, reset] = useProjectStore(s => [s.data.subdomain, s.reset]);
  const defaultApiOptions = useSuperHubStore(s => s.defaultApiOptions);

  const apiUrl = `/${subdomain}/api-next/v2/projects/me`;
  const { data: response } = useReadmeApi<ReadProjectType>(apiUrl, {
    ...defaultApiOptions,
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    if (!response) return;
    reset(response.data);
  }, [reset, response]);

  return <>{children}</>;
}
