import type { SWRConfiguration } from 'swr';

import { useContext } from 'react';
import useSWR from 'swr';

import { APIBaseUrlContext } from '@core/context';

import fetcher from './fetcher';
import useUnauthorizedRedirect from './useUnauthorizedRedirect';

export type Options = Parameters<typeof fetcher>[1] & {
  /**
   * Specifies a base URL that is prepended to the `path` when constructing the
   * request URL. By default, a base URL is derived from `APIBaseUrlContext`,
   * which is usually set for child projects of an enterprise group.
   *
   * If base URL already exists in the `path` argument, the base URL provided in
   * this option or `APIBaseUrlContext` is ignored and not prepended again.
   */
  apiBaseUrl?: string;
  swr?: SWRConfiguration;
  useLegacyBaseUrl?: boolean;
};

/**
 * A hook that wraps useSWR to get the caching and perfomance benefits of SWR
 * when reading data from the Readme API.
 */
function useReadmeApi<Data>(path: string | null, options: Options = {}, isReady = true) {
  const legacyApiBaseUrl = useContext(APIBaseUrlContext);
  // Extract SWR options out of the options hash.
  const {
    apiBaseUrl: optionApiBaseUrl,
    swr: swrOptions = {},
    useLegacyBaseUrl = true,
    ...fetcherOptions
  } = options || {};

  const baseUrl = optionApiBaseUrl || (useLegacyBaseUrl ? legacyApiBaseUrl : '');

  let normalizedPath = path;
  if (typeof path === 'string') {
    // Ensure path always begins with a leading slash.
    normalizedPath = path.startsWith('/') ? path : `/${path}`;
    // Ensure path begins with the base URL.
    normalizedPath = baseUrl && normalizedPath.startsWith(baseUrl) ? normalizedPath : `${baseUrl}${normalizedPath}`;
  }

  // NOTE: These SWR options are using default values different than SWRs.
  // https://swr.vercel.app/docs/options#options
  swrOptions.revalidateOnMount ??= true;
  swrOptions.revalidateOnFocus ??= false;
  swrOptions.shouldRetryOnError ??= false;

  const requestUrl = normalizedPath && isReady ? normalizedPath : null;
  const swrKey = requestUrl ? ([requestUrl, fetcherOptions] as const) : null;
  const { data, error, mutate, isValidating } = useSWR<Data>(swrKey, fetcher, swrOptions);

  useUnauthorizedRedirect(error);

  return {
    data,
    error,

    /**
     * True when fetching is complete and a valid `data` or `error` object has
     * been set, allowing either to be parsed and displayed to the user. Use
     * this property to conditionally display a loading state in the UI.
     */
    isLoading: normalizedPath && isReady ? !error && !data : false,

    /**
     * True if there's a request or revalidation loading via SWR
     */
    isValidating,

    /**
     * Use this to perform mutations on the current data set. See
     * https://swr.vercel.app/docs/mutation for more patterns on how to
     * configure optimistic updates and auto-rollbacks on error.
     */
    mutate,

    /**
     * Returns reference to the SWR request key that was used to fetch data.
     * This key is used by the SWR cache during `mutate()` calls and can be used
     * by the global `mutate` function to override cached data from the internal
     * `useSWR` hook here.
     */
    swrKey,
  };
}

export { default as useReadmeApiNext } from './next';
export { default as useReadmeApiInfinite } from './infinite';
export { default as useReadmeApiWrite } from './write';
export { fetcher };
export default useReadmeApi;
